import React from 'react'

const NotFoundPage = () => {
    return (
        <main style={{textAlign: 'center'}}>
            <h1>404</h1>
            <h2>Nothing here :/</h2>
        </main>
    )
}

export default NotFoundPage